body, html {
  --tw-bg-opacity: 1;
  background-color: rgb(239 234 212 / var(--tw-bg-opacity));
  font-family: var(--font-editorial), sans-serif;
  --tw-text-opacity: 1;
  color: rgb(130 61 65 / var(--tw-text-opacity));
  overscroll-behavior-y: none;
  overscroll-behavior-x: none;
  margin: 0;
}

*, :after, :before {
  box-sizing: border-box;
  border: 0 solid;
}

:after, :before {
  --tw-content: "";
}

:host, html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

code, kbd, pre, samp {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

blockquote, dd, dl, figure, h1, h2, h3, h4, h5, h6, hr, p, pre, fieldset {
  margin: 0;
}

fieldset, legend {
  padding: 0;
}

menu, ol, ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

input::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}

textarea::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}

[role="button"], button {
  cursor: pointer;
}

audio, canvas, embed, iframe, img, object, svg, video {
  vertical-align: middle;
  display: block;
}

[hidden] {
  display: none;
}

*, :after, :before, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

.-mt-1 {
  margin-top: -.25rem;
}

.h-\[205px\] {
  height: 205px;
}

.h-screen {
  height: 100svh;
}

.w-\[461px\] {
  width: 461px;
}

.space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.75rem * var(--tw-space-y-reverse));
}

.space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.border-maroon {
  --tw-border-opacity: 1;
  border-color: rgb(130 61 65 / var(--tw-border-opacity));
}

.bg-maroon, .hover\:bg-maroon:hover {
  background-color: #823d41;
}

.hover\:color-pale-yellow:hover {
  color: #efead4;
}

.border-pale-yellow {
  --tw-border-opacity: 1;
  border-color: rgb(239 234 212 / var(--tw-border-opacity));
}

.bg-pale-yellow {
  --tw-bg-opacity: 1;
  background-color: rgb(239 234 212 / var(--tw-bg-opacity));
}

.fill-maroon {
  fill: #823d41;
}

.object-cover {
  -o-object-fit: cover;
  object-fit: cover;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.font-denim {
  font-family: var(--font-denim), sans-serif;
}

.font-editorial {
  font-family: var(--font-editorial), sans-serif;
}

.shadow-pale-yellow {
  --tw-shadow-color: #efead4;
  --tw-shadow: var(--tw-shadow-colored);
}

@media (width >= 1024px) {
  .lg\:container {
    width: 100%;
  }

  @media (width >= 640px) {
    .lg\:container {
      max-width: 640px;
    }
  }

  @media (width >= 768px) {
    .lg\:container {
      max-width: 768px;
    }
  }

  @media (width >= 1024px) {
    .lg\:container {
      max-width: 1024px;
    }
  }

  @media (width >= 1280px) {
    .lg\:container {
      max-width: 1280px;
    }
  }

  @media (width >= 1536px) {
    .lg\:container {
      max-width: 1536px;
    }
  }
}

.hover\:border-maroon:hover {
  --tw-border-opacity: 1;
  border-color: rgb(130 61 65 / var(--tw-border-opacity));
}

@media (width >= 1024px) {
  .lg\:block {
    display: block;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:h-\[504px\] {
    height: 504px;
  }

  .lg\:w-\[1105px\] {
    width: 1105px;
  }

  .lg\:w-\[300px\] {
    width: 300px;
  }

  .lg\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .lg\:py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }
}

@font-face {
  font-family: __editorial_1a1e67;
  src: url("f4c97528ab80511f-s.p.77c647ca.woff2") format("woff2");
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: __editorial_1a1e67;
  src: url("a35c3a14a7bfaecf-s.p.17d4916b.woff2") format("woff2");
  font-display: swap;
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: __editorial_Fallback_1a1e67;
  src: local(Arial);
  ascent-override: 130.15%;
  descent-override: 35.64%;
  line-gap-override: 12.18%;
  size-adjust: 82.2%;
}

.__className_1a1e67 {
  font-family: __editorial_1a1e67, __editorial_Fallback_1a1e67;
}

.__variable_1a1e67 {
  --font-editorial: "__editorial_1a1e67", "__editorial_Fallback_1a1e67";
}

@font-face {
  font-family: __denim_d229dc;
  src: url("558d799c365f0201-s.p.c880e503.woff2") format("woff2");
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: __denim_Fallback_d229dc;
  src: local(Arial);
  ascent-override: 108.48%;
  descent-override: 22.95%;
  line-gap-override: 0.0%;
  size-adjust: 95.96%;
}

.__className_d229dc {
  font-family: __denim_d229dc, __denim_Fallback_d229dc;
  font-style: normal;
  font-weight: 400;
}

.__variable_d229dc {
  --font-denim: "__denim_d229dc", "__denim_Fallback_d229dc";
}

.project {
  & a {
    position: relative;

    &:before {
      content: "";
      opacity: 0;
      z-index: 2;
      background-color: #ffffff4d;
      width: 100%;
      height: 100%;
      transition: all .2s;
      position: absolute;
      top: 0;
      left: 0;
    }

    &:hover {
      &:before {
        opacity: 1;
      }
    }
  }
}

.chevron {
  animation: 1s infinite bounce;
}

@keyframes bounce {
  0%, 100% {
    animation-timing-function: cubic-bezier(.8, 0, 1, 1);
    transform: translateY(-35%);
  }

  50% {
    animation-timing-function: cubic-bezier(0, 0, .2, 1);
    transform: none;
  }
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden]:where(:not([hidden="until-found"])) {
  display: none;
}

.container {
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (width >= calc(600px + 2rem)) {
  .container {
    max-width: calc(600px + 2rem);
  }
}

@media (width >= calc(800px + 4rem)) {
  .container {
    max-width: calc(800px + 4rem);
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= calc(1400px + 6rem)) {
  .container {
    max-width: calc(1400px + 6rem);
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.inset-0 {
  inset: 0;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.-mb-\[40px\] {
  margin-bottom: -40px;
}

.mt-20 {
  margin-top: 5rem;
}

.mt-auto {
  margin-top: auto;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.hidden {
  display: none;
}

.aspect-\[1105\/510\] {
  aspect-ratio: 1105 / 510;
}

.h-\[76px\] {
  height: 76px;
}

.h-auto {
  height: auto;
}

.h-full {
  height: 100%;
}

.min-h-\[100vh\] {
  min-height: 100vh;
}

.w-\[100\%\] {
  width: 100%;
}

.w-\[150px\] {
  width: 150px;
}

.w-\[30px\] {
  width: 30px;
}

.w-full {
  width: 100%;
}

.max-w-\[461px\] {
  max-width: 461px;
}

.max-w-\[50em\] {
  max-width: 50em;
}

.max-w-sm {
  max-width: 24rem;
}

.flex-1 {
  flex: 1;
}

.shrink-0 {
  flex-shrink: 0;
}

.basis-2\/3 {
  flex-basis: 66.6667%;
}

.basis-\[60\%\] {
  flex-basis: 60%;
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-24 {
  gap: 6rem;
}

.gap-3 {
  gap: .75rem;
}

.space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.25rem * var(--tw-space-x-reverse));
  margin-left: calc(.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-12 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(3rem * var(--tw-space-x-reverse));
  margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse)));
}

.overflow-hidden {
  overflow: hidden;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-full {
  border-radius: 9999px;
}

.border {
  border-width: 1px;
}

.border-b {
  border-bottom-width: 1px;
}

.object-cover {
  object-fit: cover;
}

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.pb-12 {
  padding-bottom: 3rem;
}

.pb-24 {
  padding-bottom: 6rem;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-\[14px\] {
  font-size: 14px;
}

.text-\[16px\] {
  font-size: 16px;
}

.text-\[18px\] {
  font-size: 18px;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.uppercase {
  text-transform: uppercase;
}

.italic {
  font-style: italic;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-500 {
  transition-duration: .5s;
}

@media (width >= calc(1400px + 6rem)) {
  .lg\:block {
    display: block;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:max-w-\[1105px\] {
    max-width: 1105px;
  }

  .lg\:w-\[300px\] {
    width: 300px;
  }
}

@media (width >= calc(600px + 2rem)) {
  .sm\:basis-\[25\%\] {
    flex-basis: 25%;
  }

  .sm\:basis-\[40\%\] {
    flex-basis: 40%;
  }

  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:flex-row-reverse {
    flex-direction: row-reverse;
  }

  .sm\:gap-0 {
    gap: 0;
  }

  .sm\:gap-3 {
    gap: .75rem;
  }

  .sm\:max-w-\[725px\] {
    max-width: 725px;
  }

  .sm\:max-w-\[840px\] {
    max-width: 840px;
  }

  .sm\:ml-3 {
    margin-left: .75rem;
  }

  .sm\:ml-auto {
    margin-left: auto;
  }

  .sm\:mr-3 {
    margin-right: .75rem;
  }

  .sm\:mt-\[-20\%\] {
    margin-top: -20%;
  }

  .sm\:mt-\[-30\%\] {
    margin-top: -30%;
  }

  .sm\:mt-\[20\%\] {
    margin-top: 20%;
  }

  .sm\:mt-\[30\%\] {
    margin-top: 30%;
  }

  .sm\:mt-\[40\%\] {
    margin-top: 40%;
  }

  .sm\:mt-\[50\%\] {
    margin-top: 50%;
  }

  .sm\:mt-\[70\%\] {
    margin-top: 70%;
  }

  .sm\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}
/*# sourceMappingURL=index.4b318b3a.css.map */
